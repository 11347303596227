import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom'

import AuthContext from './context/AuthContext';
import type { } from '@mui/x-date-pickers/themeAugmentation';
import type { } from '@mui/x-charts/themeAugmentation';
import type { } from '@mui/x-data-grid/themeAugmentation';
import type { } from '@mui/x-tree-view/themeAugmentation';
import { alpha, Theme } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppNavbar from './components/AppNavbar';
import Header from './components/Header';
import HomePage from './components/pages/HomePage';
import CampaignsPage from './components/pages/CampaignsPage';
import FilesPage from './components/pages/FilesPage';
import SideMenu from './components/SideMenu';
import AppTheme from './shared-theme/AppTheme';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from './theme/customizations';
import axios from 'axios';
import LoginButton from './components/LoginButton';
import ModeContext from './context/ModeContext'
import PrivacyPolicyPage from "./components/pages/PrivacyPolicyPage";
import TermsOfServicePage from "./components/pages/TermsOfServicePage";

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export default function Dashboard(props: { disableCustomTheme?: boolean }) {
  const authContext = useContext(AuthContext);
  const modeContext = useContext(ModeContext);

  const [loading, setLoading] = React.useState(false);

  return (
    <Router>
      <AppTheme {...props} themeComponents={xThemeComponents}>
        <CssBaseline enableColorScheme />
        <Box sx={{ display: 'flex' }}>


          {authContext?.user?.email && <SideMenu />}
          {authContext?.user?.email && <AppNavbar />}
          {/* Main content */}
          <Box
            component="main"
            sx={(theme: Theme) => ({
              flexGrow: 1,
              backgroundColor: alpha(theme.palette.background.default, 1),
              overflow: 'auto',
            })}
          >
            
            {!authContext?.user?.email && <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 2,
                border: '1px solid #ccc',
                borderRadius: 1,
                marginTop: 4,
                // backgroundColor: '#f9f9f9'
              }}
            >
              <Typography variant="h6" gutterBottom>
                Login Required
              </Typography>
              <Typography variant="body1">
                Please log with Patreon.
              </Typography>
              <LoginButton />
            </Box>}

            {authContext?.user?.email && <Stack
              spacing={2}
              sx={{
                alignItems: 'center',
                mx: 3,
                pb: 5,
                mt: { xs: 8, md: 0 },
              }}
            >
              <Header />

              <Routes>
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/terms-of-service" element={<TermsOfServicePage />} />
                <Route path="/" element={<HomePage />} />
                <Route path="/home" element={<HomePage />} />
                {modeContext.siteMode === 'creator' && <Route path="/campaigns" element={<CampaignsPage />} />}
                <Route path="/files" element={<FilesPage />} />
                {/* <Route exact={true} path="/manufacturer/profile/list" element={<ListManufacturerProfilesPage />} /> */}
              </Routes>
              {/* <MainGrid /> */}
            </Stack>}
          </Box>
        </Box>
      </AppTheme>
    </Router>
  );
}
