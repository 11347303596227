export default function PrivacyPolicyPage() {
  return (
    <div className="max-w-3xl mx-auto p-6 text-gray-800">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <p className="mb-4">Effective Date: [Insert Date]</p>

      <h2 className="text-2xl font-semibold mt-6">1. Introduction</h2>
      <p>Backer Zone ("we," "our," or "us") respects your privacy and is committed to protecting it.</p>

      <h2 className="text-2xl font-semibold mt-6">2. Data Collection & Usage</h2>
      <p>We do not store personally identifiable information (PII) on our infrastructure...</p>

      <h2 className="text-2xl font-semibold mt-6">3. Cookies & Tracking</h2>
      <p>We may use cookies or similar technologies to improve user experience...</p>

      <h2 className="text-2xl font-semibold mt-6">4. User Rights & Data Control</h2>
      <p>Since we do not store PII, any requests to modify or delete personal data...</p>

      <h2 className="text-2xl font-semibold mt-6">5. Third-Party Services</h2>
      <p>We integrate with Patreon and Auth0...</p>

      <h2 className="text-2xl font-semibold mt-6">6. Data Security</h2>
      <p>We take reasonable precautions to protect user data...</p>

      <h2 className="text-2xl font-semibold mt-6">7. Changes to This Policy</h2>
      <p>We may update this Privacy Policy from time to time...</p>

      <h2 className="text-2xl font-semibold mt-6">8. Contact Information</h2>
      <p>For any questions, contact us at [Insert Contact Email].</p>
    </div>
  );
}
