import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

interface OverlayLoaderProps {
  loading: boolean;
  size?: number;
  color?: "primary" | "secondary" | "inherit";
  backgroundOpacity?: number;
}

const OverlayLoader: React.FC<OverlayLoaderProps> = ({
  loading,
  size = 60,
  color = "primary",
  backgroundOpacity = 0.5,
}) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.modal + 1,
        backgroundColor: `rgba(0, 0, 0, ${backgroundOpacity})`,
      }}
      open={loading}
    >
      <CircularProgress size={size} color={color} />
    </Backdrop>
  );
};

export default OverlayLoader;
