import { createContext } from 'react';
import { CampaignDetails } from '../types';
import { Campaign } from '../../../infra/lambda/backend/services/types'

interface Auth {
  token: string;
  user: any;
  profile: any;
  campaigns: Campaign[] | null
}
const AuthContext = createContext({} as Auth);

export default AuthContext;