import * as React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import Divider, { dividerClasses } from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import { paperClasses } from '@mui/material/Paper';
import { listClasses } from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon, { listItemIconClasses } from '@mui/material/ListItemIcon';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import MenuButton from './MenuButton';
import { useAuth0 } from "@auth0/auth0-react";
import ModeContext from '../context/ModeContext'
import OptionsMenuItems from './OptionsMenuItems';
import Avatar from '@mui/material/Avatar';
import AuthContext from '../context/AuthContext';
import { Typography } from '@mui/material';
const MenuItem = styled(MuiMenuItem)({
  margin: '2px 0',
});

export default function OptionsMenu() {
  const { user, isAuthenticated, isLoading, logout, loginWithRedirect } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()
  const modeContext = useContext(ModeContext)
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <MenuButton
        aria-label="Open menu"
        onClick={handleClick}
        sx={{ borderColor: 'transparent', width: '100%', padding: '12px' }}
      >
        <Avatar
          sizes="small"
          alt={authContext.user.email}
          src={authContext.user.picture}
          sx={{ width: 24, height: 24 }}
        />
        &nbsp;
        <Typography component="p">
          {authContext.user.email}
        </Typography>
        {/* <MoreVertRoundedIcon /> */}
      </MenuButton>
      <Menu
        anchorEl={anchorEl}
        id="menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        sx={{
          [`& .${listClasses.root}`]: {
            padding: '4px',
          },
          [`& .${paperClasses.root}`]: {
            padding: 0,
          },
          [`& .${dividerClasses.root}`]: {
            margin: '4px -4px',
          },
        }}
      >
        <OptionsMenuItems handleClose={handleClose} />

      </Menu>
    </React.Fragment>
  );
}
