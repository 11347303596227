import * as React from 'react';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography, IconButton, CircularProgress, Pagination } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, CloudUpload, Download } from '@mui/icons-material';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import ModeContext, { SiteModes } from '../context/ModeContext';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import * as config from '../config/config';
import { putFile, deleteFile, getUploadUrl, uploadToS3PresignedUrl, getDownloadUrl, getDownloadUrlForPatron } from '../service';
import { EventBus, EventNames } from '../utils/Events';

interface FileRow {
  id: string;
  title: string;
  tiers: string[];
  file: {
    content?: string;
    name?: string;
    size?: string;
    type?: string;
    key?: string;
  };
  isNew?: boolean;
}

export default function FileGridView() {
  const authContext = React.useContext(AuthContext);
  const modeContext = React.useContext(ModeContext);
  const isCreator = modeContext.siteMode === SiteModes.creator;

  const [rows, setRows] = React.useState<FileRow[]>([]);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = React.useState(false);
  const [deleteRowInFocus, setDeleteRowInFocus] = React.useState<FileRow | null>(null);
  const [rowCount, setRowCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [nextTokens, setNextTokens] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (!authContext.token) return;

    (async () => {
      setLoading(true);
      const next = nextTokens[page - 1];
      const url = isCreator
        ? `${config.apiEndpoint}/files?limit=${pageSize}${next ? `&next=${next}` : ''}`
        : `${config.apiEndpoint}/patron/files?limit=${pageSize}${next ? `&next=${next}` : ''}`;
      
      try {
        const { data } = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${authContext.token}`,
            'X-Api-Key': config.apiKey,
          },
        });

        const mappedFiles = data.files.map((f: any) => ({ ...f, isNew: false }));
        if (nextTokens.length === page - 1) {
          setNextTokens([...nextTokens, data.next]);
        }

        setRows(mappedFiles);
        setRowCount(data.count);
      } catch (error) {
        console.error('Error fetching files:', error);
      } finally {
        setLoading(false);
      }
    })();
  }, [authContext.token, page, pageSize]);

  const handleAddFile = () => {
    const newFile: FileRow = { id: `new-${Date.now()}`, title: '', tiers: [], file: {}, isNew: true };
    setRows((prev) => [newFile, ...prev]);
  };

  const handleDeleteFile = async () => {
    if (!deleteRowInFocus) return;
    await deleteFile(deleteRowInFocus, authContext.token);
    setRows((prev) => prev.filter((row) => row.id !== deleteRowInFocus.id));
    setConfirmDeleteDialogOpen(false);
  };

  const handleDownloadFile = async (row: FileRow) => {
    if (isCreator && !row.file?.key) {
      console.error('No file key available for download.');
      return;
    }
    EventBus.emit(EventNames.START_LOADING, {})

    try {
      const { url } = isCreator
        ? await getDownloadUrl(row.file.key || '', authContext.token)
        : await getDownloadUrlForPatron(row.id, authContext.token);

      const response = await axios.get(url, { responseType: 'blob' });
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = row.file?.name || 'download';
      link.click();
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
          EventBus.emit(EventNames.STOP_LOADING, {})
    }
  };

  return (
    <Box sx={{ maxWidth: '100%', padding: 2 }}>
      {/* Confirm Delete Dialog */}
      <ConfirmDeleteDialog
        open={confirmDeleteDialogOpen}
        fileName={deleteRowInFocus?.file?.name || ''}
        onClose={() => setConfirmDeleteDialogOpen(false)}
        onConfirm={handleDeleteFile}
      />

      {/* Add New File Button */}
      {isCreator && (
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddFile} sx={{ mb: 2 }}>
          Add File
        </Button>
      )}

      {/* Loading Spinner */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: 2, width: '100%' }}>
          {rows.map((row) => (
            <Card key={row.id} sx={{ maxWidth: 300, display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="div"
                sx={{
                  height: 140,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#f5f5f5',
                }}
              >
                <CloudUpload fontSize="large" color="action" />
              </CardMedia>

              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6">{row.title || 'Untitled File'}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {row.file?.name || 'No file uploaded'}
                </Typography>
                <Typography variant="caption">
                  {row.file?.type ? `${row.file.type} - ${((row?.file?.size || 0) as number / 1024).toFixed(2)} KB` : 'No details available'}
                </Typography>
              </CardContent>

              <CardActions sx={{ justifyContent: 'space-between' }}>
                {/* Download Button */}
                <IconButton color="primary" onClick={() => handleDownloadFile(row)}>
                  <Download />
                </IconButton>

                {/* Edit and Delete Actions */}
                {isCreator && (
                  <>
                    <IconButton color="primary">
                      <EditIcon />
                    </IconButton>
                    <IconButton color="error" onClick={() => { setDeleteRowInFocus(row); setConfirmDeleteDialogOpen(true); }}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              </CardActions>
            </Card>
          ))}
        </Box>
      )}

      {/* Pagination Controls */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Pagination
          count={Math.ceil(rowCount / pageSize)}
          page={page}
          onChange={(_, newPage) => setPage(newPage)}
          color="primary"
        />
      </Box>
    </Box>
  );
}
