import * as React from 'react';
import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import Divider, { dividerClasses } from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import { paperClasses } from '@mui/material/Paper';
import { listClasses } from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon, { listItemIconClasses } from '@mui/material/ListItemIcon';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import MenuButton from './MenuButton';
import { useAuth0 } from "@auth0/auth0-react";
import ModeContext from '../context/ModeContext'
import ColorModeIconDropdown from '.././shared-theme/ColorModeIconDropdown';
import { useColorScheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
const MenuItem = styled(MuiMenuItem)({
  margin: '2px 0',
});

export default function OptionsMenuItems(props: any = {}) {
  const modeContext = useContext(ModeContext)
  const navigate = useNavigate()
  const { mode, systemMode, setMode } = useColorScheme();
  const { user, isAuthenticated, isLoading, logout, loginWithRedirect } = useAuth0();
  const handleMode = (targetMode: 'system' | 'light' | 'dark') => () => {
    setMode(targetMode);

  };
  return <>
    <Typography sx={{ padding: '4px' }} fontWeight={800} color="textSecondary" >Visual Mode</Typography>
    <Divider />
    <MenuItem selected={mode === 'system'} onClick={handleMode('system')}>
      System
    </MenuItem>
    <MenuItem selected={mode === 'light'} onClick={handleMode('light')}>
      Light
    </MenuItem>
    <MenuItem selected={mode === 'dark'} onClick={handleMode('dark')}>
      Dark
    </MenuItem>
    <Divider />
    <Typography sx={{ padding: '4px' }} fontWeight={800} color="textSecondary">User Mode</Typography>
    <MenuItem onClick={() => {
      modeContext.setCreatorMode()
      navigate('/home')
    }}
      selected={modeContext.siteMode === 'creator'}
    >
      Creator Mode
    </MenuItem>
    <MenuItem onClick={() => {
      modeContext.setPatronMode()
      navigate('/home')
    }}
      selected={modeContext.siteMode === 'patron'}
    >
      Patron Mode
    </MenuItem>
    <Divider />
    {isAuthenticated && <MenuItem
      onClick={() => {
        if (props.handleClose) props.handleClose()
        logout({ logoutParams: { returnTo: window.location.origin } })
      }}
      sx={{
        [`& .${listItemIconClasses.root}`]: {
          ml: 'auto',
          minWidth: 0,
        },
      }}
    >
      <ListItemText>Logout</ListItemText>
      <ListItemIcon>
        <LogoutRoundedIcon fontSize="small" />
      </ListItemIcon>
    </MenuItem>}
    {!isAuthenticated && <MenuItem
      onClick={() => {
        if (props.handleClose) props.handleClose()
        loginWithRedirect()
      }}
      sx={{
        [`& .${listItemIconClasses.root}`]: {
          ml: 'auto',
          minWidth: 0,
        },
      }}
    >
      <ListItemText>Login</ListItemText>
      <ListItemIcon>
        <LoginRoundedIcon fontSize="small" />
      </ListItemIcon>
    </MenuItem>}
  </>
}