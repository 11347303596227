import axios from 'axios'
import * as config from '../config/config'

const apiKey = config.apiKey

export const deleteFile = async (file: any, token: any) => {
  const response = await axios.delete(
    `${config.apiEndpoint}/files/${file.id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Api-Key": apiKey,
      },
    }
  );
  return response;
};

export const putFile = async (file: any, token: any) => {
  const response = await axios.put(
    `${config.apiEndpoint}/files`,
    { ...file, file: { ...file.file, content: "" } },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Api-Key": apiKey,
      },
    }
  );
  return response;
};

export const getUploadUrl = async (token: any) => {
  const response = await axios.get(
    `${config.apiEndpoint}/files/upload-url`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Api-Key": apiKey,
      },
    }
  );
  return response.data;
};

export const getDownloadUrl = async (fileKey: string, token: any) => {
  const response = await axios.get(
    `${config.apiEndpoint}/files/download-url/${fileKey}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Api-Key": apiKey,
      },
    }
  );
  return response.data;
};

export const getDownloadUrlForPatron = async (fileKey: string, token: any) => {
  const response = await axios.get(
    `${config.apiEndpoint}/patron/files/download-url/${fileKey}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Api-Key": apiKey,
      },
    }
  );
  return response.data;
};


export async function uploadToS3PresignedUrl(
  presignedUrl: string,
  file: any | { content: string | ArrayBuffer | null; type: string }
): Promise<void> {
  try {
    // Ensure `content` is a valid binary format
    if (!file.content) {
      throw new Error('File content is empty.');
    }

    // Convert base64 data (if necessary) into binary
    let binaryData: any;
    if (typeof file.content === 'string' && file.content.startsWith('data:')) {
      const base64Data = file.content.split(',')[1];
      binaryData = Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0));
    } else {
      binaryData = file.content;
    }

    const response = await fetch(presignedUrl, {
      method: 'PUT',
      body: binaryData,
      headers: {
        'Content-Type': file.type || 'application/octet-stream',
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to upload to S3: ${response.statusText}`);
    }

    console.log('Upload successful!');
  } catch (error) {
    console.error('Error uploading to S3:', error);
    throw error;
  }
}
