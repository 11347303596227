import { AuthorizationParams } from '@auth0/auth0-react';

// export const domain = "dev-pqo5l2ab0asidqrv.us.auth0.com"
export const domain = "auth.app.backerz.one"
export const clientId = "4csygo2KEiGjKeSPssrXo19eUPel01lo"
export const authorizationParams: AuthorizationParams = {
  redirect_uri: window.location.origin,
  connection: 'Patreon-Test'
}

export const apiEndpoint = 'https://sbx.api.backerz.one/v0.1'
export const audience = 'https://sbx.api.backerz.one'
export const apiKey = 'kna793h790n5v26cbhjalof08cn03b77cv619nxg'