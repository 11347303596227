export default function TermsOfService() {
  return (
    <div className="max-w-3xl mx-auto p-6 text-gray-800">
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
      <p className="mb-4">Effective Date: [Insert Date]</p>

      <h2 className="text-2xl font-semibold mt-6">1. Acceptance of Terms</h2>
      <p>By using Backer Zone, you agree to these Terms of Service...</p>

      <h2 className="text-2xl font-semibold mt-6">2. Description of Service</h2>
      <p>Backer Zone allows Patreon creators to upload digital content...</p>

      <h2 className="text-2xl font-semibold mt-6">3. User Responsibilities</h2>
      <p>Users must comply with all applicable laws...</p>

      <h2 className="text-2xl font-semibold mt-6">4. Content & Copyright</h2>
      <p>Backer Zone does not claim ownership of user-uploaded content...</p>

      <h2 className="text-2xl font-semibold mt-6">5. Limitations of Liability</h2>
      <p>Backer Zone is not responsible for any content uploaded by users...</p>

      <h2 className="text-2xl font-semibold mt-6">6. Account Termination</h2>
      <p>We reserve the right to suspend or terminate any user account...</p>

      <h2 className="text-2xl font-semibold mt-6">7. Modifications to Terms</h2>
      <p>We may update these Terms at any time...</p>

      <h2 className="text-2xl font-semibold mt-6">8. Governing Law</h2>
      <p>These Terms are governed by the laws of [Insert Jurisdiction]...</p>

      <h2 className="text-2xl font-semibold mt-6">9. Contact Information</h2>
      <p>For any questions, contact us at [Insert Contact Email].</p>
    </div>
  );
}
