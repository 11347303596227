import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlotProps,
  GridRenderEditCellParams,
  useGridApiContext,
  GridPaginationModel
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';
import { FormControl, IconButton, MenuItem, Select, Typography } from '@mui/material';
import AuthContext from '../context/AuthContext';
import ModeContext, { SiteModes } from '../context/ModeContext';
import { CloudUpload, Download } from '@mui/icons-material';
import axios from 'axios'
import { putFile, deleteFile, getUploadUrl, uploadToS3PresignedUrl, getDownloadUrl, getDownloadUrlForPatron } from '../service';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import * as config from '../config/config'
import { EventBus, EventNames } from '../utils/Events';

interface FileRow {
  id: string
  title: string
  campaigns: string[]
  tiers: string[]
  file: {
    content?: string
    name: string
    size: string
    type: string
    key: string
  }
  creatorName: string
  creatorId: string
  isNew?: boolean
}

declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides {
    setRows: (newRows: (oldRows: FileRow[]) => FileRow[]) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }
}

function EditToolbar(props: GridSlotProps['toolbar']) {
  const authContext = React.useContext(AuthContext);
  const { setRows, setRowModesModel } = props;

  const { profile } = authContext

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id, title: '',
        campaigns: [],
        tiers: [],
        file: {
          name: '',
          size: '',
          type: '',
          key: '',
        },
        isNew: true,
        creatorName: profile.identity.attributes.full_name,
        creatorId: profile.identity.id
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add File
      </Button>
    </GridToolbarContainer>
  );
}

export default function FullFeaturedCrudGrid() {

  const authContext = React.useContext(AuthContext);
  const modeContext = React.useContext(ModeContext);

  const isCreator = modeContext.siteMode === SiteModes.creator

  const [rows, setRows] = React.useState([] as FileRow[]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = React.useState(false)
  const [deleteRowInFocus, setDeleteRowInFocus] = React.useState({} as any)
  const [rowCount, setRowCount] = React.useState(0); // Total rows count for server-side paging
  const [page, setPage] = React.useState(0); // Current page (0-indexed)
  const [pageSize, setPageSize] = React.useState(10); // Rows per page
  const [loading, setLoading] = React.useState(false)
  const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel>({
    page: 0,
    pageSize: 10, // Default page size
  });
  const [nextTokens, setNextTokens] = React.useState<any[]>([])

  React.useEffect(() => {
    if (!authContext.token) return;

    (async () => {
      setLoading(true)
      const next = nextTokens[paginationModel.page - 1]
      const url = modeContext.siteMode === SiteModes.creator
        ? `${config.apiEndpoint}/files?limit=${paginationModel.pageSize}${next ? `&next=${next}` : ''}`
        : `${config.apiEndpoint}/patron/files?limit=${paginationModel.pageSize}${next ? `&next=${next}` : ''}`
      const { data } = await axios.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${authContext.token}`,
            'X-Api-Key': config.apiKey,
          },
        });
      const mapped = data.files.map((f: any) => {
        f.isNew = false
        return f
      })
      const numberOfNextTokens = nextTokens.length
      const thisPage = paginationModel.page
      if (numberOfNextTokens === thisPage) {
        setNextTokens([...nextTokens, data.next])
      }
      setRows(mapped as any)
      setRowCount(data.count)
      setLoading(false)
    })();
  }, [authContext.token, paginationModel]);

  const CustomCampaignsEditCell = (props: GridRenderEditCellParams) => {
    const { id, value, field, hasFocus, row } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef<HTMLInputElement | null>(null);

    React.useLayoutEffect(() => {
      if (hasFocus && ref.current) {
        ref.current.focus();
      }
    }, [hasFocus]);

    const handleValueChange = (event: any) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return <FormControl fullWidth>
      <Select
        id="campaign"
        sx={{ margin: '2px', padding: '2px' }}
        // size='small'
        value={value || []}
        multiple
        label="Campaign"
        onChange={handleValueChange}
      >
        {authContext.campaigns?.map((c: any) => <MenuItem value={c.id}>{c.name}</MenuItem>)}
      </Select>
    </FormControl>
  }

  const CustomTiersEditCell = (props: GridRenderEditCellParams) => {
    const { id, value, field, hasFocus, row } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef<HTMLInputElement | null>(null);
    const selectedCampaigns = row.campaigns || []
    const tierOptions = selectedCampaigns.reduce((s: any, c: any) => {
      const fullCampaign = authContext.campaigns?.find((f: any) => f.id === c)
      s.push(...(fullCampaign?.tiers || []))
      return s
    }, [])

    React.useLayoutEffect(() => {
      if (hasFocus && ref.current) {
        ref.current.focus();
      }
    }, [hasFocus]);

    const handleValueChange = (event: any) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return <FormControl fullWidth>
      <Select
        id="tiers"
        sx={{ margin: '2px', padding: '2px' }}
        // size='small'
        value={value || []}
        label="Age"
        multiple
        onChange={handleValueChange}
      >
        {tierOptions.map((t: any) => <MenuItem value={t.id}>{t.title}</MenuItem>)}
      </Select>
    </FormControl>
  }

  const handleDownload = () => { }
  const renderFileDetails = (file: any) => {
    const boxStyle: any = {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
    if (file) {
      return (
        <Box style={{ ...boxStyle, flexDirection: 'column' }}>
          <Typography variant="body2">{file.name}</Typography>
          <Typography variant="caption" color="textSecondary">
            {`${file.type} - ${(file.size / 1024).toFixed(2)} KB`}
          </Typography>
        </Box>
      );
    }
    return (
      <Box style={{ ...boxStyle, flexDirection: 'row' }}>
        <CloudUpload style={{ marginRight: 8 }} />
        <Typography variant="body2">Choose file</Typography>
      </Box>
    );
  };

  const CustomFileEditCell: React.FC<any> = (props: GridRenderEditCellParams) => {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();
    const [file, setFile] = React.useState<File | null>(value ? (value as File) : null);
    const [fileContent, setFileContent] = React.useState<string | ArrayBuffer | null>(null); // New state to hold file content
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useLayoutEffect(() => {
      if (hasFocus && inputRef.current) {
        inputRef.current?.focus();
      }
    }, [hasFocus]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = event.target.files ? event.target.files[0] : null;
      if (selectedFile) {
        setFile(selectedFile);
        // Read file content
        const reader = new FileReader();
        reader.onload = () => {
          setFileContent(reader.result); // Store the file content

          apiRef.current.setEditCellValue({
            id, field, value: {
              name: selectedFile.name,
              size: selectedFile.size,
              type: selectedFile.type,
              content: reader.result
            }
          }); // Save file and content
        };
        reader.readAsDataURL(selectedFile); // You can change this to `readAsText` or other methods as needed
      }
    };

    const handleButtonClick = () => {
      if (inputRef.current) {
        inputRef.current.click();
      }
    };

    return (
      <FormControl fullWidth>
        <input
          ref={inputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <Button onClick={handleButtonClick} variant="outlined" fullWidth>
          {renderFileDetails(file)}
        </Button>
      </FormControl>
    );
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (event: any) => {
    const { row } = event
    setRowModesModel({ ...rowModesModel, [row.id]: { mode: GridRowModes.View } });
  };

  const handleDownloadFile = async (event: any) => {
    EventBus.emit(EventNames.START_LOADING, {})
    const { row } = event

    if (isCreator && !row.file?.key) {
      console.error('No file key available for download.');
      return;
    }

    try {
      // Step 1: Get the presigned URL from your backend
      const { url, key } = isCreator
        ? await getDownloadUrl(row.file.key, authContext.token)
        : await getDownloadUrlForPatron(row.id, authContext.token)

      // Step 2: Fetch the file using the presigned URL
      const response = await axios.get(url, {
        responseType: 'blob', // Ensure the file is downloaded as binary data
      });

      // Step 3: Create a download link
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = row.file?.name || 'download';
      link.click();
    } catch (error) {
      console.error('Error downloading the file:', error);
    } finally {
      EventBus.emit(EventNames.STOP_LOADING)
    }
  };

  const handleDeleteClick = (event: any) => () => {
    const { row } = event
    setDeleteRowInFocus(row)
    setConfirmDeleteDialogOpen(true)
  };

  const onConfirmDeleteFile = async () => {
    await deleteFile(deleteRowInFocus, authContext.token)
    setRows(rows.filter((_row) => _row.id !== deleteRowInFocus?.id));
    setConfirmDeleteDialogOpen(false)
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow: FileRow = rows.find((row: FileRow) => row.id === id) || {} as FileRow;
    if (editedRow.isNew) {
      setRows(rows.filter((row: FileRow) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow: FileRow): Promise<FileRow> => {
    EventBus.emit(EventNames.START_LOADING)
    const updatedRow: FileRow = { ...newRow, isNew: false };
    const _rows: FileRow[] = structuredClone(rows)
    const __rows: FileRow[] = _rows.map((row: FileRow) => (row.id === newRow.id ? updatedRow : row)) || []
    setRows(__rows);

    if (newRow.file.content) {
      const { key, url } = await getUploadUrl(authContext.token)
      newRow.file.key = key
      await uploadToS3PresignedUrl(url, newRow.file)
    }
    await putFile(newRow, authContext.token)
    EventBus.emit(EventNames.STOP_LOADING)
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Title', flex: 1, editable: true },
  ]

  if (modeContext.siteMode === SiteModes.creator) {
    columns.push({
      field: 'campaigns',
      headerName: 'Campaigns',
      type: 'custom',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      renderCell: (params: any) => {
        const { row } = params;
        if (!authContext.campaigns) return <CircularProgress sx={{ padding: '10px' }} />
        return (
          row.campaigns?.map((selectedCampaign: any, index: number) => {
            const campaign: any = authContext.campaigns?.find((f: any) => f.id === selectedCampaign)
            return campaign ? <span className={`pill legend-${index}`} key={index}>{campaign?.name}</span> : null
          }) || null
        );
      },
      renderEditCell: props => <CustomCampaignsEditCell {...props} />,
    })

    columns.push({
      field: 'tiers',
      headerName: 'Tiers',
      type: 'custom',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      renderCell: (params: any) => {
        const { row } = params;
        if (!authContext.campaigns) return <CircularProgress sx={{ padding: '10px' }} />
        const allTiers = authContext.campaigns?.reduce((s: any, c: any, index: number) => {
          s.push(...(c?.tiers || []).map((t: any) => {
            return {
              ...t,
              className: `legend-${index}`
            }
          }))
          return s
        }, []) || []

        return (
          row.tiers?.map((selectedTier: any, index: number) => {
            const tier = allTiers.find((f: any) => f.id === selectedTier)
            return tier ? <span className={`pill ${tier.className}`} key={index}>{tier?.title}</span> : null
          }) || null
        );
      },
      renderEditCell: props => <CustomTiersEditCell {...props} />,
    })
  }

  columns.push({
    field: 'file',
    headerName: 'File',
    type: 'custom',
    flex: 1,
    editable: true,
    renderCell: (params: any) => {
      return renderFileDetails(params?.row?.file)
    },
    renderEditCell: props => <CustomFileEditCell {...props} />,
  })
  columns.push({
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    flex: 1,
    cellClassName: 'actions',
    getActions: (row) => {
      const { id } = row
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      const editModeActions = [
        <GridActionsCellItem
          icon={<SaveIcon />}
          label="Save"
          sx={{
            color: 'primary.main',
          }}
          onClick={() => handleSaveClick(row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(row)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<CancelIcon />}
          label="Cancel"
          className="textPrimary"
          onClick={handleCancelClick(id)}
          color="inherit"
        />,
      ]

      if (isInEditMode) return editModeActions

      const readActions = [
        <GridActionsCellItem
          icon={<Download />}
          label="Download"
          sx={{
            color: 'primary.main',
          }}
          onClick={() => handleDownloadFile(row)}
        />
      ]

      if (modeContext.siteMode === SiteModes.creator) {
        readActions.push(<GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />)
      }

      return readActions
    },
  })


  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <ConfirmDeleteDialog
        open={confirmDeleteDialogOpen}
        fileName={deleteRowInFocus.file?.name}
        onClose={() => setConfirmDeleteDialogOpen(false)}
        onConfirm={() => onConfirmDeleteFile()}
      />
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{ toolbar: modeContext.siteMode === SiteModes.creator ? EditToolbar : undefined }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        pagination
        paginationMode="server" // Enable server-side pagination
        rowCount={rowCount} // Total number of rows from the server
        loading={loading} // Show loading spinner while fetching data
        paginationModel={paginationModel} // Controlled pagination model
        onPaginationModelChange={setPaginationModel} // Handle pagination state changes
        pageSizeOptions={[10, 50, 100]} // Allowed page sizes
      />
    </Box>
  );
}
