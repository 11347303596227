import React, { useEffect, createContext, useContext } from 'react';
import logo from './logo.svg';
import './App.css';
import Dashboard from './Dashboard';
import { useAuth0 } from "@auth0/auth0-react";
import AuthContext from './context/AuthContext';
import Loading from './components/Loading'
import axios from 'axios'
import { CampaignDetails, PatreonResponse } from './types';
import ModeContext, { SiteModes } from './context/ModeContext';
import { useLocalStorage } from "./utils";
import { Campaign } from '../../infra/lambda/backend/services/types'
import * as config from './config/config'
import OverlayLoader from './components/OverlayLoader';
import { EventBus, EventNames } from './utils/Events';

function App() {

  const [token, setToken] = React.useState('');
  const [_user, setUser] = React.useState('' as any);
  const [profile, setProfile] = React.useState('' as any);
  const [loading, setLoading] = React.useState(true);
  const [campaigns, setCampaigns] = React.useState<Campaign[] | null>(null);
  const [siteMode, setSiteMode] = useLocalStorage<SiteModes>("siteMode", SiteModes.patron);
  const [files, setFiles] = React.useState<any[] | null>(null);
  const { user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  const [userMetadata, setUserMetadata] = React.useState(null);

  useEffect(() => {
    console.log('create listeners')
    const startCallback = () => {
      console.log('setloading event')
      setLoading(true)
    }
    const stopCallback = () => setLoading(false)
    EventBus.addListener(EventNames.START_LOADING, startCallback)
    EventBus.addListener(EventNames.STOP_LOADING, stopCallback)

    return () => {
      console.log('remove listeners')
      EventBus.removeListener(EventNames.START_LOADING, startCallback)
      EventBus.removeListener(EventNames.STOP_LOADING, stopCallback)
    }
  }, [])

  useEffect(() => {

    const getUserMetadata = async () => {
      setLoading(true)
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: config.audience,
            scope: "profile",
          },
        });
        setToken(accessToken);
        // if (user) {
        //   setUser(user)
        // }
      } catch (e: any) {
        if((e.message || "").toLowerCase() === 'consent required') {
          console.log('consent required')
        }
        console.log(e.message);
      } finally {
        setLoading(false)
      }
    };
    getUserMetadata();
  }, [getAccessTokenSilently, user]);

  useEffect(() => {
    console.log(_user)
  }, [_user]);

  useEffect(() => {
    if (!token) {
      return;
    }
    (async () => {
      const result = await axios.get(`${config.apiEndpoint}/creator/campaigns`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Api-Key': config.apiKey,
        },
      });
      const data: Campaign[] = result.data
      setCampaigns(data);

    })();
    (async () => {
      const result = await axios.get(`${config.apiEndpoint}/user-profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Api-Key': config.apiKey,
        },
      });
      const data: any = result.data
      setProfile(data);

    })();
  }, [token]);

  const setCreatorMode = () => {
    setSiteMode(SiteModes.creator)
  }

  const setPatronMode = () => {
    setSiteMode(SiteModes.patron)
  }

  return <AuthContext.Provider value={{
    user, //: _user,
    profile,
    token,
    campaigns
  }}>
    <ModeContext.Provider value={{
      siteMode,
      setCreatorMode,
      setPatronMode,
    }}>
      {/* {loading && <Loading message="Loading..." />} */}
      <OverlayLoader loading={loading}/>
      <Dashboard />
    </ModeContext.Provider>
  </AuthContext.Provider>
}

export default App;
